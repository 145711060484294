import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/common/services/chat.service';
import { RequesterService } from 'src/app/common/services/requester.service';
import { Channel } from 'stream-chat';

@Component({
  selector: 'app-attender-messaging',
  templateUrl: './attender-messaging.component.html',
  styleUrls: ['./attender-messaging.component.scss']
})
export class AttenderMessagingComponent {
  groups: Channel[] = [];
  selectedGroup: Channel | null = null;
  selectedGroupId: string = '';
  jobDetails: any = null;
  constructor(
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    private requesterService: RequesterService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['channelId']) {
        this.selectedGroupId = params['channelId'];
      }
    });
  }

  ngOnInit(): void {

    this.chatService.isConnected$.subscribe(isConnected => {
      if (isConnected) {
        this.chatService.channels$.subscribe(channels => {
          this.groups = channels;
          if (this.selectedGroupId) {
            if (this.groups.find(group => group.id === this.selectedGroupId)) {
              this.onGroupSelect(this.groups.find(group => group.id === this.selectedGroupId));
            }
          } else {
            this.onGroupSelect(this.selectedGroup || this.groups[0]);
          }

          console.log('AttenderMessagingComponent initialized', this.groups);
        });
        this.chatService.reloadChannels();
      } else {
        this.groups = [];
      }
    });
  }

  onGroupSelect(group: Channel): void {
    this.selectedGroup = group;
    if (group?.data?.job_id) {
      this.getJobStatus(group.data.job_id);
    }
  }

  getJobStatus(rfq) {
    console.log(rfq);
    this.jobDetails = null;
    this.requesterService.request('get', `jobs/rfqs/biddable-jobs/${rfq}`).subscribe(data => {
      console.log(data);
      this.jobDetails = data.data;
    });
  }
}
