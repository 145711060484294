import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
  @Input() messages: any[] = [];
  @Input() loading: boolean = false;
  @Input() currentUser: any = {};
  gridStyle = {
    width: '50%',
    height: '100px',
    textAlign: 'center',
    padding: '5px'
  };
  constructor() { }

  ngOnInit() {
  }


}
