import { Component, OnInit } from '@angular/core';
import { RequesterService } from '../../services/requester.service';

@Component({
  selector: 'app-free-trial-modal',
  templateUrl: './free-trial-modal.component.html',
  styleUrls: ['./free-trial-modal.component.scss']
})
export class FreeTrialModalComponent implements OnInit {
  totalJobs: number = 0;
  usedJobs: number = 0;
  constructor(
    private requesterService: RequesterService
  ) {
  }

  ngOnInit(): void {
    this.getTrialInfo();
  }

  getTrialInfo() {
    this.requesterService.request('get', 'billing-systems/seller-fee').subscribe((res: any) => {
      console.log('Trial info:', res);
      this.totalJobs = res.data.sellerFreeTrial;
      this.usedJobs = res.data.completedJobs;
    });
  }


  get remainingJobs(): number {
    return this.totalJobs - this.usedJobs;
  }

  get usagePercentage(): number {
    return (this.usedJobs / this.totalJobs) * 100;
  }

  get progressStatus(): 'success' | 'exception' | 'active' | 'normal' {
    if (this.usagePercentage >= 50) {
      return 'exception';
    }
    return this.usedJobs === this.totalJobs ? 'success' : 'active';
  }

  getStatusColor(): string {
    if (this.usagePercentage >= 50) {
      return 'warning';
    } else if (this.usagePercentage === 0) {
      return 'success';
    }
    return 'processing';
  }

  getStatisticColor(): string {
    if (this.usagePercentage >= 50) {
      return '#faad14'; // warning color
    }
    return '#52c41a'; // success color
  }

}
