import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ChatService } from 'src/app/common/services/chat.service';
import { StatusService } from 'src/app/common/services/status.service';
import { Channel, Message } from 'stream-chat';

@Component({
  selector: 'app-attender-chat-window',
  templateUrl: './attender-chat-window.component.html',
  styleUrls: ['./attender-chat-window.component.scss']
})
export class AttenderChatWindowComponent {
  @ViewChild('scrollElement') scrollElement: ElementRef;
  likes = 0;
  dislikes = 0;
  @Input() group!: Channel;
  @Input() jobDetails: any = null;
  messages: Message[] = [];
  newMessage: string = '';
  showParticipants: boolean = false;

  // For demo purposes, we'll use a fixed current user
  currentUser: any = null;
  members: any[] = [];

  constructor(
    private message: NzMessageService,
    private chatService: ChatService,
    public statusService: StatusService
  ) { }

  userTypeMap: any = {
    'admin': { title: 'Admin', color: 'red' },
    'owner': { title: 'Buyer', color: 'green' },
    'vendor': { title: 'Seller', color: 'blue' }
  }

  ngOnInit(): void {
    this.currentUser = this.chatService.getCurrentUser();
    this.chatService.messages$.subscribe(messages => {
      this.messages = messages;
      console.log('Messages:', this.messages);
      this.scrollToBottom();
    });
  }


  scrollToBottom(): void {
    if (this.scrollElement) {
      // Scroll to the bottom of the chat window by animating the scrollTop property
      setTimeout(() => {
        this.scrollElement.nativeElement.scrollTo({
          top: this.scrollElement.nativeElement.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);

    }
  }



  ngOnChanges(changes: SimpleChanges) {

    if (changes.group && changes.group.currentValue) {
      this.loadMessages();
    }

  }

  async loadMessages(): Promise<void> {
    this.members = Object.values(this.group.state.members).map(m => m.user).filter(u => u.userType !== 'admin');
    // console.log('Members:', this.members);
    await this.chatService.watchChannel(this.group.id)
    // mark as read
    await this.chatService.markRead();
  }



  toggleParticipants(): void {
    this.showParticipants = !this.showParticipants;
  }

  like(): void {
    this.likes = 1;
    this.dislikes = 0;
  }

  dislike(): void {
    this.likes = 0;
    this.dislikes = 1;
  }


  getSenderName(senderId: string): string {
    const sender = this.group.state.members[senderId];
    return sender ? sender.user?.name : 'Unknown';
  }

  selectedFiles: File[] = [];

  handleAttachment(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 0) {
      this.selectedFiles.push(...Array.from(files));
      this.message.success(`File ${files[0].name} selected`);
      // Reset file input
      event.target.value = '';
    }
  }

  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  sendMessage(): void {
    if (!this.newMessage.trim() && this.selectedFiles.length === 0) return;

    // Here you would handle both message and files

    this.chatService.sendMessage(this.newMessage.trim(), this.selectedFiles);

    this.newMessage = '';
    this.selectedFiles = [];
  }
}
