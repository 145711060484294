// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:5001',
  mediaBaseUrl: 'https://testapi.attender.ai/uploads/',
  // mediaBaseUrl: 'http://localhost:5000/uploads/',

  apiUrl: 'https://testapi.attender.ai',
  // apiUrl: 'http://localhost:5001',
  stripeUrl: 'https://paymentdev.attender.ai',
  stripePublicKey: 'pk_test_51P1MKNItVaMpopZmHla746N0ucrrk8YSn49WG2dNjlWPbZJaFsr2P5VDaygWaso8h50CWLo6mW3DIXmB7k7Jx3MT00966Xhhyq',
  // stripeUrl :  'http://65.21.144.56:5002',
  googleLoginPublicKey: '20431365903-vlekuj276tp3o8dqqpej8htftolutcl3.apps.googleusercontent.com',
  sessionTimeOutInSeconds: 7200,
  streamChat: {
    apiKey: 'ejwbqm3tdubn',
    appId: '1347152',
  },
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
