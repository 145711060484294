import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Channel } from 'stream-chat';

@Component({
  selector: 'app-attender-chat-list',
  templateUrl: './attender-chat-list.component.html',
  styleUrls: ['./attender-chat-list.component.scss']
})
export class AttenderChatListComponent {
  @Input() groups: Channel[] = [];
  @Input() selectedGroup: Channel | null = null;
  @Output() groupSelect = new EventEmitter<Channel>();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    console.log('AttenderChatListComponent initialized', this.groups);
  }

  selectGroup(group: Channel): void {
    this.groupSelect.emit(group);
  }
}
