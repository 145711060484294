import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { DefaultComponent } from './layouts/default/default.component';
import { NoSidebarComponent } from './layouts/no-sidebar/no-sidebar.component';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { HomeComponent } from './misc/frontend/home/home.component';
import { HeaderComponent } from './layouts/no-sidebar/header/header.component';
import { FooterComponent } from './layouts/no-sidebar/footer/footer.component';
import {ChatModule} from './main/chat/chat.module';
import { GlobalSearchComponent } from './layouts/no-sidebar/header/global-search/global-search.component';
import {SharedModuleModule} from './common/shared-module/shared-module.module';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import { TermsConditionPublicComponent } from './misc/terms-condition-public/terms-condition-public.component';
import { PrivacyPolicyPublicComponent } from './misc/privacy-policy-public/privacy-policy-public.component';
import { NgxStripeModule } from 'ngx-stripe';
import { TermsConditionModalComponent } from './common/components/terms-condition-modal/terms-condition-modal.component';
import { ChatWindowModule } from './main/chat-window/chat-window.module';
import { FreeTrialModalComponent } from './common/components/free-trial-modal/free-trial-modal.component';




const config: SocketIoConfig = { url: environment.apiUrl, options: {} };
const stripePk : string = environment.stripePublicKey;
const googleLoginPk : string = environment.googleLoginPublicKey;
registerLocaleData(en);
@NgModule({
    declarations: [
        AppComponent,
        DefaultComponent,
        NoSidebarComponent,
        NotFoundComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        GlobalSearchComponent,
        ChangePasswordComponent,
        TermsConditionPublicComponent,
        PrivacyPolicyPublicComponent,
        TermsConditionModalComponent,
        FreeTrialModalComponent
        
       
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChatModule,
    ChatWindowModule,
    SharedModuleModule,
    SocketIoModule.forRoot(config),
    NgxStripeModule.forRoot(stripePk),
  ],  
    providers: [{provide: NZ_I18N, useValue: en_US},BnNgIdleService,
      ],
    exports: [
    ],
    bootstrap: [AppComponent],
    entryComponents: [ChangePasswordComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
