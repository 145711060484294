// user.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userDetailsSubject = new Subject<any>();
  userDetails$ = this.userDetailsSubject.asObservable();

  constructor() {
    // Load the initial data from localStorage if available
    const storedUserDetails = localStorage.getItem('userDetails');
    if (storedUserDetails) {
      this.userDetailsSubject.next(JSON.parse(storedUserDetails));
    }
  }

  // Function to update user details
  updateUserDetails(userDetails: any) {
    this.userDetailsSubject.next(userDetails);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }
}
