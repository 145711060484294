import { Injectable } from '@angular/core';
import { connect, StreamClient } from 'getstream';
import { Observable, Subject } from 'rxjs';
import { RequesterService } from './requester.service';
import { StreamChat, ChannelData, Message, User } from 'stream-chat';
interface SpoolifyNotification {
  notificationsByGroup: any[],
  unseen: number,
  unread: number
}

@Injectable({
  providedIn: 'root',
})
export class StreamClientService {
  client: StreamClient;
  subscription: any;
  localUser: any;
  notificationFeed: any;
  $notification: Subject<any> = new Subject<any>();
  userRole = 'vendor';
  $allNotifications: Subject<SpoolifyNotification> = new Subject<SpoolifyNotification>();
  username: string | null = null;
  chatClient: StreamChat;
  currentUser: any;
  constructor(private requesterService: RequesterService) {
    // Instantiate a new client (client side)
  }

  getRealtimeNotification(): Observable<any> {
    return this.$notification as Observable<any>;
  }

  async getAllNotifications(page: number = 1, limit: number = 15) {
    try {
      const result = await this.notificationFeed.get({ limit, skip: (page - 1) * limit });
      const spoolifyNotification: SpoolifyNotification = {
        notificationsByGroup: result.results,
        unread: result.unread,
        unseen: result.unseen
      };
      this.$allNotifications.next(spoolifyNotification);
      console.log('LOGGING');
    } catch (e) {
      throw e;
    }
  }

  async markNotificationAsRead(notificationIds: string[]) {
    this.notificationFeed.get({ mark_read: notificationIds });
    this.getAllNotifications();
  }

  async connect() {
    try {
      // const response = await this.requesterService.request('get', 'auth/authenticate-activity').toPromise();
      // const { token, appId, appToken } = response.data;
      // this.client = connect(appToken, token, appId);
      // this.localUser = JSON.parse(localStorage.getItem('userDetails') || '');
      // const userId = this.userRole + '_' + this.localUser._id;
      // this.notificationFeed = this.client.feed('notification', userId, token);
      // if (!this.subscription) {
      //   this.subscription = this.notificationFeed.subscribe((data) => {
      //     for (const item of data.new) {
      //       this.$notification.next(item);
      //     }
      //     this.getAllNotifications();

      //   });
      // }

      // this.getAllNotifications();
    } catch (e) {
      console.log(e)
    }
  }


  async authenticateUser() {
    // try {
    //   const response = await this.requesterService.request('get', 'auth/authenticate-chat').toPromise();
    //   const { token } = response.data;
    //   const apiKey = response.data.api_key;
    //   this.username = response.data.user.username;
    //   const name = response.data.user.name;
    //   this.chatClient = new StreamChat(apiKey);
    //   this.currentUser = await this.chatClient.setUser(
    //     {
    //       id: this.username,
    //       name,
    //     },
    //     token
    //   );
    //   console.log(this.currentUser);
    //   return { apiKey, token, user: this.currentUser };
    // } catch (e) {
    //   throw e;
    // }
  }

  async getUnreadCountsByChannelIds(channelIds: string[]) {
    // try {
    //   const filter = { id: { $in: channelIds }, members: { $in: [this.username] } };
    //   const sort = { last_message_at: -1 };

    //   // const channels = await this.chatClient.queryChannels(filter, sort, {
    //   //     watch: true, // this is the default
    //   //     state: true,
    //   // });

    //   // channels.map((channel) => {
    //   //     console.log(channel, channel.state.read[this.username]['unread_messages']);
    //   // });
    //   const unreadCounts = {};
    //   // for(const channelId of channelIds) {
    //   //   unreadCounts[channelId] = 0;
    //   //   const foundData = channels.find((channel) => channel.id === channelId);
    //   //   if(foundData) {
    //   //     unreadCounts[channelId] = foundData.state.read[this.username]['unread_messages'];
    //   //   }
    //   // }
    //   return unreadCounts;
    // } catch (error) {
    //   throw error;
    // }
  }


  async disconnect() {
    if (this.subscription) {
      this.subscription.cancel();
    }
  }
}
