import { Component, HostListener, OnInit } from "@angular/core";
import { AuthService } from "../../common/services/auth.service";
import { User } from "../../common/models/user";
import { NavigationEnd, Router } from "@angular/router";
import { MenuService } from "src/app/common/services/menu.service";
import { BreadcrumbService } from "../../common/services/breadcrumb.service";
import { StartChatService } from "src/app/common/services/start-chat.service";
import { CommunicationService } from "src/app/common/services/communication.service";
import { ChatDispatcherService } from "src/app/main/chat/services/chat-dispatcher.service";
import { RequesterService } from "src/app/common/services/requester.service";
import { ChatService } from "src/app/common/services/chat.service";
@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.scss"],
})
export class DefaultComponent implements OnInit {
  userDetails: any;
  menuData: any[] = [];
  currentUrl = "";
  isCollapsed = false;
  isWorkspace = localStorage.getItem("app") === "workspace";
  breadcrumbArr: any[] = [];
  chatIconType = "message";
  masterOpen = true;
  unreadcount = 0;
  requestedUsersCount: number = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenuService,
    private breadcrumbService: BreadcrumbService,
    private startChatService: StartChatService,
    private communicationService: CommunicationService,
    private _chatDispatcherService: ChatDispatcherService,
    private requesterService: RequesterService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    this.userDetails = AuthService.getUserDetails();
    if (this.userDetails.role !== "external") {
      this.menuData = AuthService.isCompanyAdmin()
        ? this.menuService.getLeftMenu()
        : this.menuService.getLeftMenu(["dashboard"]);
      this.menuData = this.menuData.filter(
        (item) => item.key !== "paymentHistory"
      );
    } else {
      this.getWorkspacesForExternal().then((data) => {
        console.log("EXTERNAL DATA>>>>>", data);
        this.menuData = data.map((rfq) => ({
          key: rfq._id,
          title: rfq.rfqNo,
          route: "/panel/work-space/" + rfq._id,
          icon: "message",
        }));
      });
    }
    /* -------------condition of payment history menu for paymentEnable and isCompanyAdmin-------------- */
    this.authService.vendorSubscription.subscribe((subscriptionData: any) => {
      if (subscriptionData?.paymentEnabled) {
        this.menuData = AuthService.isCompanyAdmin()
          ? this.menuService.getLeftMenu()
          : this.menuService.getLeftMenu(["dashboard"]);
      }
    });

    this.currentUrl = this.router.url;
    // this._chatDispatcherService.notificationCounter.subscribe(
    //   (subscribedData) => {
    //     this.unreadcount = subscribedData.count;
    //   }
    // );

    this.chatService.unreadCount$.subscribe((count) => {
      this.unreadcount = count;
    });
    this.authService.authState.subscribe((loggedIn) => {
      if (loggedIn) {
        this.menuData = AuthService.isCompanyAdmin()
          ? this.menuService.getLeftMenu()
          : this.menuService.getLeftMenu(["dashboard"]);
      }
    });

    this.breadcrumbService.onBreadcrumb.subscribe((data) => {
      this.breadcrumbArr = data;
    });

    localStorage.setItem("lastActiveTime", new Date().toString());
    //
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.currentUrl = event.url;
      }
    });

    // get the count of pending requested users
    let params = {};

    this.requesterService
      .request("get", "users/joining-requests", params)
      .subscribe((data) => {
        console.log("Requested users", data);

        this.requestedUsersCount = data?.totalCount;
      });

  }



  // localStorage.setItem('lastActiveTime', new Date().toString());

  async getWorkspacesForExternal(): Promise<any[]> {
    try {
      const result = await this.requesterService
        .request("get", "jobs/rfqs/external-user-workspaces")
        .toPromise();
      return result.data;
    } catch (error) {
      return [];
    }
  }

  get isBidListPage(): boolean {
    return (
      this.currentUrl.includes("shortlisted-bids") ||
      // this.currentUrl.includes("view-po") ||
      this.currentUrl.includes("attachments") ||
      this.currentUrl.includes("profile") ||
      this.currentUrl.includes("work-space") ||
      this.currentUrl.includes("request") ||
      // this.currentUrl.includes('invoice') ||
      this.currentUrl.includes("chat") ||
      this.currentUrl.includes("payment-history")
    );
  }
  logout() {
    this.authService.logout();
    this.router.navigateByUrl("/auth/login");
  }

  profile() {
    this.router.navigateByUrl("/panel/user/profile");
  }

  goToLink(url: string) {
    console.log(url);
    this.router.navigateByUrl(url);
  }

  onMenuOpenClose(event) {
    this.masterOpen = !this.masterOpen;
  }

  openChat() {
    this.chatIconType = "loading";
    setTimeout(() => {
      this.chatIconType = "message";
    }, 3000);
    this.startChatService.openChat.next(null);
  }

  download() {
    this.communicationService.downloadSubject.next(null);
  }

  menuSelected(menu) {


    if (this.currentUrl.includes(menu.route)) {
      return true
    } else {
      false
    }
    //return this.currentUrl === menu.route;
  }


  /*
  if the system is idle for 2 hours then user should logged out

  */
}
