import { Injectable } from '@angular/core';
import { StreamChat, Channel, Message, User as StreamUser } from 'stream-chat';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequesterService } from './requester.service';
import { Router } from '@angular/router';

export interface ChatUser {
  id: string;
  name: string;
  role: 'user' | 'admin' | 'moderator';
  userType: string;
}


interface IOpenChat {
  rfqId: string;
  vendorId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private userType: string = 'vendor';
  private client: StreamChat;
  private currentUser: ChatUser | null = null;
  private activeChannel: Channel | null = null;

  // Observables for real-time updates
  private messagesSubject = new BehaviorSubject<Message[]>([]);
  private channelsSubject = new BehaviorSubject<Channel[]>([]);
  private isConnectedSubject = new BehaviorSubject<boolean>(false);
  private loadChannelsSubject = new BehaviorSubject<boolean>(false);
  public openChatSubject = new Subject<IOpenChat | null>();
  private unreadCountSubject = new BehaviorSubject<number>(0);
  private chatActivity = new Subject<any>();

  messages$ = this.messagesSubject.asObservable();
  channels$ = this.channelsSubject.asObservable();
  isConnected$ = this.isConnectedSubject.asObservable();
  loadChannels$ = this.loadChannelsSubject.asObservable();
  unreadCount$ = this.unreadCountSubject.asObservable();
  chatActivity$ = this.chatActivity.asObservable();
  eventListeners: any = {};

  private channelListeners: any = {};
  constructor(private requesterService: RequesterService, private router: Router) {
    this.client = StreamChat.getInstance(environment.streamChat.apiKey);
    this.loadChannels$.subscribe((state) => {
      if (state) {
        this.loadChannels();
      }
    });
    this.isConnected$.subscribe(isConnected => {
      if (isConnected) {
        this.client.getUnreadCount(this.currentUser.id).then((response) => {
          this.unreadCountSubject.next(response.total_unread_count);
        });

        this.eventListeners['message.new'] = this.client.on('message.new', e => {
          this.chatActivity.next(e);
          this.loadChannels();
        });
        // Listen for new channel creation
        this.eventListeners['channel.created'] = this.client.on('channel.created', e => {
          this.loadChannels();
        });

        this.eventListeners['unread.count'] = this.client.on((event) => {
          if (event.total_unread_count !== undefined) {
            this.unreadCountSubject.next(event.total_unread_count);
          }

        });
      } else {
        Object.keys(this.eventListeners).forEach(key => {
          this.eventListeners[key].unsubscribe();
        });
      }
    });

    this.openChatSubject.subscribe((data: IOpenChat) => {
      if (data && data.rfqId && data.vendorId) {
        const channelId = `job_${data.rfqId}_${data.vendorId}`;
        this.router.navigate(['/panel/chat'], { queryParams: { channelId } });
      } else {
        this.router.navigate(['/panel/chat']);
      }

    });
  }


  reloadChannels(): void {
    this.loadChannelsSubject.next(true);
  }

  /**
   * Initialize and connect to Stream Chat
   */
  async initializeChat(mongoUser: any, role: string): Promise<void> {
    try {
      console.log('Initializing chat...', mongoUser);
      // Get Stream Chat token from backend
      const { data: response } = await this.requesterService.request('post', 'chats/init', {}).toPromise();

      if (!response) {
        throw new Error('Failed to initialize chat');
      }

      // Disconnect if there's an existing connection
      if (this.client.userID) {
        await this.disconnect();
      }
      // Connect user to Stream Chat
      await this.client.connectUser({
        id: response.streamUserId,
        name: mongoUser.fullName,
        role: 'user',
        userType: this.userType
      }, response.token);

      this.currentUser = {
        id: response.streamUserId,
        name: mongoUser.fullName,
        role: 'user',
        userType: this.userType
      };

      this.isConnectedSubject.next(true);
      console.log('Chat initialized', this.currentUser);
      await this.loadChannels();
    } catch (error) {
      console.error('Chat initialization failed:', error);
      throw error;
    }
  }

  /**
   * Disconnect from Stream Chat
   */
  async disconnect(): Promise<void> {
    if (this.client.userID) {
      await this.client.disconnectUser();
      this.currentUser = null;
      this.activeChannel = null;
      this.isConnectedSubject.next(false);
      this.messagesSubject.next([]);
      this.channelsSubject.next([]);
    }
  }

  /**
   * Get all channels for current user
   */
  async loadChannels(): Promise<Channel[]> {
    try {
      const filter = { members: { $in: [this.currentUser?.id] }, frozen: false };
      const sort: any[] = [{ last_message_at: -1 }];

      const channels = await this.client.queryChannels(filter, sort, {
        limit: 30
      });
      this.channelsSubject.next(channels);

      // Watch for new channels
      this.client.on('channel.created', this.handleChannelEvent.bind(this));
      this.client.on('channel.updated', this.handleChannelEvent.bind(this));
      this.client.on('channel.deleted', this.handleChannelEvent.bind(this));

      return channels;
    } catch (error) {
      console.error('Failed to load channels:', error);
      throw error;
    }
  }

  /**
   * Watch a specific channel and get its messages
   */
  async watchChannel(channelId: string): Promise<void> {
    try {
      // Cleanup previous channel listeners
      this.removeChannelListeners();

      // Unwatch previous channel if exists
      if (this.activeChannel && this.activeChannel.id !== channelId) {
        await this.activeChannel.stopWatching();
        // Clear messages
        this.messagesSubject.next([]);
      }

      // Initialize and watch new channel
      const channel = this.client.channel('team', channelId);
      await channel.watch();
      this.activeChannel = channel;

      // Load initial messages
      const response: any = await channel.query({ messages: { limit: 30 } });
      this.messagesSubject.next(response.messages);

      // Set up new listeners and store their cleanup functions
      this.channelListeners['message.new'] = channel.on('message.new', this.handleNewMessage.bind(this));
      this.channelListeners['message.updated'] = channel.on('message.updated', this.handleMessageUpdated.bind(this));
      this.channelListeners['message.deleted'] = channel.on('message.deleted', this.handleMessageDeleted.bind(this));

    } catch (error) {
      console.error('Failed to watch channel:', error);
      throw error;
    }
  }


  /**
  * Remove channel event listeners
  */
  private removeChannelListeners(): void {
    Object.values(this.channelListeners).forEach((listener: any) => {
      if (listener) {
        listener.unsubscribe();
      }
    });
    this.channelListeners = {};
  }


  /**
   * Send a message to the active channel
   */
  async sendMessage(text: string, attachments: any[] = []): Promise<Message> {
    if (!this.activeChannel) {
      throw new Error('No active channel');
    }

    try {
      const response: any = await this.activeChannel.sendMessage({
        text,
        attachments,
      });
      //Mark as read
      await this.markRead();
      this.loadChannels();

      return response.message;
    } catch (error) {
      console.error('Failed to send message:', error);
      throw error;
    }
  }

  /**
   * Edit a message
   */
  async updateMessage(messageId: string, text: string): Promise<Message> {
    try {
      const response: any = await this.client.updateMessage({
        id: messageId,
        text: text,
      });

      return response.message;
    } catch (error) {
      console.error('Failed to update message:', error);
      throw error;
    }
  }

  /**
   * Delete a message
   */
  async deleteMessage(messageId: string): Promise<void> {
    try {
      await this.client.deleteMessage(messageId);
    } catch (error) {
      console.error('Failed to delete message:', error);
      throw error;
    }
  }

  /**
   * Mark channel as read
   */
  async markRead(): Promise<void> {
    if (this.activeChannel) {
      try {
        await this.activeChannel.markRead();
      } catch (error) {
        console.error('Failed to mark channel as read:', error);
        throw error;
      }
    }
  }

  /**
   * Get channel members
   */
  async getChannelMembers(channelId: string): Promise<StreamUser[]> {
    try {
      const channel = this.client.channel('team', channelId);
      const response: any = await channel.queryMembers({});
      return response.members;
    } catch (error) {
      console.error('Failed to get channel members:', error);
      throw error;
    }
  }

  /**
   * Handle new message event
   */
  private handleNewMessage(event: { message: Message }) {
    const currentMessages = this.messagesSubject.value;
    this.messagesSubject.next([...currentMessages, event.message]);
  }

  /**
   * Handle message update event
   */
  private handleMessageUpdated(event: { message: Message }) {
    const currentMessages = this.messagesSubject.value;
    const index = currentMessages.findIndex(m => m.id === event.message.id);
    if (index !== -1) {
      const updatedMessages = [...currentMessages];
      updatedMessages[index] = event.message;
      this.messagesSubject.next(updatedMessages);
    }
  }

  /**
   * Handle message delete event
   */
  private handleMessageDeleted(event: { message: Message }) {
    const currentMessages = this.messagesSubject.value;
    this.messagesSubject.next(
      currentMessages.filter(m => m.id !== event.message.id)
    );
  }

  /**
   * Handle channel events (created, updated, deleted)
   */
  private async handleChannelEvent() {
    // Reload channels to get updated list
    await this.loadChannels();
  }

  /**
   * Get unread count for a channel
   */
  async getUnreadCount(channelId: string): Promise<number> {
    try {
      const channel = this.client.channel('team', channelId);
      const state: any = await channel.watch();
      return state.unreadCount ?? 0;
    } catch (error) {
      console.error('Failed to get unread count:', error);
      return 0;
    }
  }

  /**
   * Upload file attachment
   */
  async uploadFile(file: File): Promise<string> {
    try {
      const response = await this.requesterService.fileRequest('chats/upload', file).toPromise();

      return response?.url || '';
    } catch (error) {
      console.error('Failed to upload file:', error);
      throw error;
    }
  }

  /**
   * Get current user
   */
  getCurrentUser(): ChatUser | null {
    return this.currentUser;
  }

  /**
   * Get active channel
   */
  getActiveChannel(): Channel | null {
    return this.activeChannel;
  }
}
