import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
// import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzListModule } from "ng-zorro-antd/list";
import { NzAnchorModule } from "ng-zorro-antd/anchor";
import { LocalFilterPipe } from "../pipes/local-filter.pipe";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { TimeAgoPipe } from "../pipes/time-ago.pipe";
// import {PushNotificationsModule} from 'ng-push';
import { GlobalSearchComponent } from "../../layouts/no-sidebar/header/global-search/global-search.component";
import { VendorStatusPipe } from "../pipes/vendor-status.pipe";
import { FilterByDatePipe } from "../pipes/filter-by-date.pipe";
import { FilterByArrayFieldPipe } from "../pipes/filter-by-array-field.pipe";
import { UploadBoxComponent } from "../components/upload-box/upload-box.component";
import { MediaBoxComponent } from "../components/media-box/media-box.component";
import { FilePreviewComponent } from "../components/file-preview/file-preview.component";
import { ResizableModule } from "angular-resizable-element";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzMentionModule } from "ng-zorro-antd/mention";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzTreeModule } from "ng-zorro-antd/tree";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { PdfPreviewRenderComponent } from "../components/pdf-preview-render/pdf-preview-render.component";
import { QuickSettingsModalComponent } from "../components/quick-settings-modal/quick-settings-modal.component";
import { CompanyDetailsComponent } from "src/app/auth/vendor-post-register/company-details/company-details.component";
import { AccreditationComponent } from "src/app/auth/vendor-post-register/accreditation/accreditation.component";
import { PaymentDetailsComponent } from "src/app/auth/vendor-post-register/payment-details/payment-details.component";
import { PaymentsAndTermsComponent } from "src/app/auth/vendor-post-register/payments-and-terms/payments-and-terms.component";
import { CompanySkillsComponent } from "src/app/auth/vendor-post-register/company-skills/company-skills.component";
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { KtdGridModule } from "@katoid/angular-grid-layout";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ProfileModalComponent } from "src/app/corporate/profile-modal/profile-modal.component";


// take a variable where declare all the module thats want to share with
const Modules = [
  // NgZorroAntdModule,
  NzIconModule,
  NzBackTopModule,
  NzRateModule,
  NzSliderModule,
  NzInputNumberModule,
  NzSwitchModule,
  NzTreeModule,
  NzTableModule,
  NzDrawerModule,
  NzDescriptionsModule,
  NzToolTipModule,
  NzBreadCrumbModule,
  NzPopconfirmModule,
  NzCollapseModule,
  NzEmptyModule,
  NzCheckboxModule,
  NzStepsModule,
  NzAlertModule,
  NzProgressModule,
  NzButtonModule,
  NzInputModule,
  NzPopoverModule,
  NzCardModule,
  NzMentionModule,
  NzSpinModule,
  NzDropDownModule,
  NzUploadModule,
  NzBadgeModule,
  NzDividerModule,
  NzTagModule,
  CommonModule,
  NzSkeletonModule,
  NzFormModule,
  ReactiveFormsModule,
  NzGridModule,
  NzSelectModule,
  FormsModule,
  NzPageHeaderModule,
  NzResultModule,
  NzListModule,
  NzAnchorModule,
  InfiniteScrollModule,
  NzAvatarModule,
  // PushNotificationsModule,
  ResizableModule,
  NzModalModule,
  NzNotificationModule,
  NzLayoutModule,
  NzMessageModule,
  NzTabsModule,
  NzDatePickerModule,
  NzRadioModule,
  KtdGridModule,
  NzSpaceModule,
  NzTypographyModule

];

@NgModule({
  declarations: [
    LocalFilterPipe,
    TimeAgoPipe,
    VendorStatusPipe,
    FilterByDatePipe,
    FilterByArrayFieldPipe,
    UploadBoxComponent,
    MediaBoxComponent,
    FilePreviewComponent,
    PdfPreviewRenderComponent,
    QuickSettingsModalComponent,
    CompanyDetailsComponent,
    AccreditationComponent,
    PaymentDetailsComponent,
    PaymentsAndTermsComponent,
    CompanySkillsComponent,
    ProfileModalComponent
  ],
  imports: [...Modules],
  exports: [
    ...Modules,
    LocalFilterPipe,
    TimeAgoPipe,
    VendorStatusPipe,
    FilterByDatePipe,
    FilterByArrayFieldPipe,
    UploadBoxComponent,
    MediaBoxComponent,
    FilePreviewComponent,
    PdfPreviewRenderComponent,
    CompanyDetailsComponent,
    AccreditationComponent,
    PaymentDetailsComponent,
    PaymentsAndTermsComponent,
    CompanySkillsComponent,
    ProfileModalComponent
  ],
})
export class SharedModuleModule { }
