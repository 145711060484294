import { Component, ViewChild, ElementRef, Input, OnInit } from "@angular/core";
import { RequesterService } from "../../services/requester.service";
import { NzModalRef } from "ng-zorro-antd/modal";
import { AuthService } from "../../services/auth.service";
import jsPDF from "jspdf";
import { Router } from "@angular/router";

@Component({
  selector: "app-terms-condition-modal",
  templateUrl: "./terms-condition-modal.component.html",
  styleUrls: ["./terms-condition-modal.component.scss"],
})
export class TermsConditionModalComponent implements OnInit {
  @Input("data") data: any;
  checkStatus: boolean = false;
  @ViewChild("pdfElement", { static: false }) pdfElement!: ElementRef;

  constructor(
    private requesterService: RequesterService,
    private authService: AuthService,
    private modalRef: NzModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  downloadAsPDF() {
    console.log("Download as PDF");
    const doc = new jsPDF("p", "pt", "a4");
    doc.addFont("ArialMS", "Arial", "normal");
    doc.setFont("Arial");

    const specialElementHandlers = {
      "#editor": function (element, renderer) {
        return true;
      },
    };

    console.log(this.pdfElement);
    const pdfElement = this.pdfElement.nativeElement;

    doc.fromHTML(pdfElement.innerHTML, 15, 15, {
      width: 540,
      // 'elementHandlers': specialElementHandlers
    });

    doc.save("Attender Vendor Membership Agreement.pdf");
  }
  // Method to accept terms and conditions
  acceptTermsAndCondition(): void {

    this.requesterService
      .request("put", `vendor-companies/${this.data._id}/payment-terms`, {})
      .subscribe(() => {
        this.modalRef.close(true);
      });
  }

  // Method to reject terms and conditions
  rejectTermsAndCondition(): void {
    console.log("Terms Rejected");

    this.authService.logout().then((status) => {

      this.router.navigateByUrl("/auth/login");
      this.modalRef.close(false);

    });

  }
}
