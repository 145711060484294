import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener

} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { filter } from "rxjs";
import { AuthService } from "src/app/common/services/auth.service";
import { ChatService } from "src/app/common/services/chat.service";


interface ChatMessage {
  text: string;
  sender: string;
  timestamp: Date;
  initials?: string;
}

interface RFQChat {
  id: string;
  lastMessage: Date;
}

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})


export class ConversationComponent implements OnInit {

  isOpen = false;
  unreadCount = 0;
  selectedRFQ: string | null = null;
  //newMessage = '';

  @ViewChild('fileInput') fileInput!: ElementRef;

  messageText: string = '';
  showEmojiPicker: boolean = false;
  allowedFileTypes: string = '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx'; // Add/remove as needed


  @ViewChild('messageInput') messageInput!: ElementRef;
  newMessage: string = '';
  inputHeight: number = 30;

  isJobPage = false;
  rfqId = '';
  recentChats: RFQChat[] = [
    { id: 'S912297', lastMessage: new Date('2024-08-22 20:54:40') },
    { id: 'S652239', lastMessage: new Date('2024-08-22 12:32:59') },
    { id: 'S900970', lastMessage: new Date('2024-08-22 12:26:43') },
    { id: 'S459602', lastMessage: new Date('2024-08-21 17:14:39') },
    { id: 'S480054', lastMessage: new Date('2024-08-21 15:14:39') },
    { id: 'S746031', lastMessage: new Date('2024-08-21 14:14:39') }
  ];

  currentMessages: ChatMessage[] = [];
  currentUser: any = null;
  constructor(private message: NzMessageService,
    private router: Router,
    private chatService: ChatService
  ) {
    this.currentUser = AuthService.getUserDetails();
    console.log('Current user:', this.currentUser);
    // Read route changes
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // read the current route
        const currentRoute = this.router.url;
        // console.log('Current route:', currentRoute);
        const splitRoute = currentRoute.split('/');
        console.log('Split route:', splitRoute);
        if (splitRoute[1] === 'panel' && splitRoute[2] === 'bidding') {
          this.isJobPage = true;
          this.rfqId = splitRoute[3];
        } else {
          this.isJobPage = false;
        }
      });
  }

  ngOnInit() {
    // Initialize with sample messages
    this.currentMessages = [
      {
        text: 'I am admin',
        sender: 'Johannes Kepler',
        timestamp: new Date('2024-11-05 12:17:11'),
        initials: 'Admin'
      },
      {
        text: 'I am agent',
        sender: 'Johannes Kepler',
        timestamp: new Date('2024-11-05 12:29:57'),
        initials: 'Agent'
      },
      {
        text: 'I am owner',
        sender: 'Johannes Kepler',
        timestamp: new Date('2024-11-05 13:30:05'),
        initials: 'Owner'
      },
      {
        text: 'I am vendor',
        sender: 'Johannes Kepler',
        timestamp: new Date('2024-11-05 13:30:05'),
        initials: 'Vendor'
      }
    ];
  }

  toggleChat() {
    // this.isOpen = !this.isOpen;
    // if (this.isOpen) {
    //   this.unreadCount = 0;
    // }
    if (this.isJobPage) {
      this.chatService.openChatSubject.next({
        rfqId: this.rfqId,
        vendorId: this.currentUser.company
      })
    } else {
      this.chatService.openChatSubject.next(null);
    }

    this.chatService.unreadCount$.subscribe((count) => {
      this.unreadCount = count;
    } );
  }

  openRFQChat(rfqId: string) {
    this.selectedRFQ = rfqId;
    // Here you would typically load the messages for this RFQ
  }

  closeRFQChat() {
    this.selectedRFQ = null;
  }

  // sendMessage() {
  //   if (this.newMessage.trim()) {
  //     this.currentMessages.push({
  //       text: this.newMessage,
  //       sender: 'user',
  //       timestamp: new Date()
  //     });
  //     this.newMessage = '';
  //   }
  // }
  // sendMessage() {
  //   if (this.newMessage.trim()) {
  //     this.currentMessages.push({
  //       text: this.newMessage,
  //       sender: 'user',  // This marks it as your message
  //       timestamp: new Date()
  //     });
  //     this.newMessage = '';
  //   }
  // }
  sendMessage() {
    if (this.newMessage.trim()) {
      this.currentMessages.push({
        text: this.newMessage,
        sender: 'user',  // This will make your messages appear on the right with red background
        timestamp: new Date()
      });
      this.newMessage = '';
    }
  }

  getColor(role: string): string {
    switch (role) {
      case 'Admin':
        return 'red';
      case 'Owner':
        return 'blue';
      case 'Vendor':
        return 'green';
      case 'Agent':
        return 'purple';

    }
  }

  // Toggle emoji picker
  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  // Add emoji to message
  addEmoji(event: any): void {
    const emoji = event.emoji.native;
    this.messageText += emoji;
    this.showEmojiPicker = false;
  }

  // Handle file attachment
  handleFileInput(event: any): void {
    const files = event.target.files;
    if (files.length > 0) {
      // Handle multiple files
      Array.from(files).forEach((file: any) => {
        if (this.validateFile(file)) {
          this.uploadFile(file);
        }
      });
    }
    // Reset file input
    this.fileInput.nativeElement.value = '';
  }

  // Validate file
  private validateFile(file: File): boolean {
    const maxSize = 5 * 1024 * 1024; // 5MB limit
    if (file.size > maxSize) {
      this.message.error(`File ${file.name} is too large. Maximum size is 5MB`);
      return false;
    }

    // Check file type
    const allowedTypes = this.allowedFileTypes.split(',');
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
    if (!allowedTypes.includes(fileExtension)) {
      this.message.error(`File type ${fileExtension} is not allowed`);
      return false;
    }

    return true;
  }

  // Upload file
  private uploadFile(file: File): void {
    // Create FormData
    const formData = new FormData();
    formData.append('file', file);

    // Show upload progress
    this.message.loading(`Uploading ${file.name}...`);

    // Implement your file upload logic here
    // Example:
    // this.chatService.uploadFile(formData).subscribe({
    //   next: (response) => {
    //     this.message.success(`${file.name} uploaded successfully`);
    //     this.sendFileMessage(response.fileUrl);
    //   },
    //   error: (error) => {
    //     this.message.error(`Failed to upload ${file.name}`);
    //   }
    // });
  }
  // Close emoji picker when clicking outside
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.emoji-picker-container') &&
      !target.closest('.emoji-button')) {
      this.showEmojiPicker = false;
    }
  }

  autoGrow(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '30px'; // Reset height to minimum
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to content height
  }

  adjustHeight(): void {
    const element = this.messageInput.nativeElement;
    // Reset height temporarily to get the correct scrollHeight
    element.style.height = 'auto';

    // Calculate new height based on content
    const maxHeight = 150; // 6 rows approximately
    const newHeight = Math.max(30, Math.min(element.scrollHeight, maxHeight));
    element.style.height = `${newHeight}px`;
    this.inputHeight = newHeight;

    // If content exceeds max height, ensure cursor is visible
    if (element.scrollHeight > maxHeight) {
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 0);
    }
  }

}
