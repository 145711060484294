import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatWindowRoutingModule } from './chat-window-routing.module';
import { SharedModuleModule } from 'src/app/common/shared-module/shared-module.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConversationComponent } from './conversation/conversation.component';
import { ChatListComponent } from './conversation/chat-list/chat-list.component';




@NgModule({
  declarations: [
    ConversationComponent,ChatListComponent

  ],
  exports: [
    ConversationComponent
  ],
  imports: [
    CommonModule,
    ChatWindowRoutingModule,
    SharedModuleModule,
    DragDropModule,
   
  ]
})
export class ChatWindowModule { }
