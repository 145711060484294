import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { FloatingChatComponent } from './floating-chat/floating-chat.component';
import { SharedModuleModule } from '../../common/shared-module/shared-module.module';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MessageListComponent } from './floating-chat/message-list/message-list.component';
import { AttenderMessagingComponent } from './attender-messaging/attender-messaging.component';
import { AttenderChatListComponent } from './attender-chat-list/attender-chat-list.component';
import { AttenderChatWindowComponent } from './attender-chat-window/attender-chat-window.component';


@NgModule({
  declarations: [FloatingChatComponent, MessageListComponent, AttenderMessagingComponent,
    AttenderChatListComponent,
    AttenderChatWindowComponent],
  exports: [
    FloatingChatComponent
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModuleModule,
    DragDropModule
  ]
})
export class ChatModule { }
