import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { StreamChat, ChannelData, Message, User } from "stream-chat";
import { RequesterService } from "../../../common/services/requester.service";
import {

  NzUploadFile,
  UploadFilter,
} from "ng-zorro-antd/upload";
import {
  NzMessageService,


} from "ng-zorro-antd/message";
import {

  NzNotificationService,

} from "ng-zorro-antd/notification";
import { ChatDispatcherService } from "../services/chat-dispatcher.service";
import { ResizeEvent } from "angular-resizable-element";
import { Observable, Observer } from "rxjs";
import { environment } from "../../../../environments/environment";
// import {file} from "@rxweb/reactive-form-validators";
import { Router } from "@angular/router";
import { CommunicationService } from "src/app/common/services/communication.service";
@Component({
  selector: "app-floating-chat",
  templateUrl: "./floating-chat.component.html",
  styleUrls: ["./floating-chat.component.scss"],
})
export class FloatingChatComponent implements OnInit {
  loading = false;
  maximized = false;
  contactList = "vendor";
  data = [
    {
      title: "John Legend",
      company: "Lorem Legend",
      rfq: "RFQ-S458569",
    },
    {
      title: "Json Bourn",
      company: "Bourn Legacy",
      rfq: "RFQ-S458569",
    },
    {
      title: "Alex Buddy",
      company: "Alex Corp",
      rfq: "RFQ-S458569",
    },
    {
      title: "Suman Mallik",
      company: "Broadifi Tech",
      rfq: "RFQ-S458569",
    },
  ];
  openSingle = false;
  singleExpanded = false;
  title = "angular-chat";
  channel: ChannelData;
  username = "";
  messages: Message[] = [];
  newMessage = "";
  channelList: ChannelData[] = [];
  currentChannelList: ChannelData[] = [];
  chatClient: any;
  currentUser: User;
  channelType = "team";
  companyName = "";
  messageListener: any;
  chatWindowName = "";
  unreadCount = 0;
  customStyle: any = {
    width: "500px",
    height: "320px",
  };
  mediaUploadURL = environment.apiUrl + "/api/media";
  fileList: any[] = [];
  customHeaders: any = {
    Authorization: "Bearer " + localStorage.getItem("bearerToken"),
  };
  messageSending = false;
  attachmentsLoading = false;
  rfqId: string;
  filters: UploadFilter[] = [
    {
      name: "type",
      fn: (fileList: NzUploadFile[]) => {
        // tslint:disable-next-line:no-bitwise
        const filterFiles = fileList.filter(
          (w) =>
            ~[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/tiff",
              "application/pdf",
              "application/msword",

              // Added MIME type for doc files

              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-powerpoint",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              "application/rtf",
              "application/zip",
              "application/x-gzip",
              "application/x-tar",
              "application/x-bzip2",
              "text/plain",
              "text/html",
              "image/gif",
              "audio/mpeg",
              "audio/ogg",
              "video/mp4",
              "video/ogg",
              "video/webm",

            ].indexOf(w.type)
        );
        if (filterFiles.length !== fileList.length) {
          this.msg.error(`Some Files are off type`);
          return filterFiles;
        }
        return fileList;
      },
    },
    {
      name: "async",
      fn: (fileList: NzUploadFile[]) => {
        return new Observable((observer: Observer<NzUploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      },
    },
  ];
  limit = 10;
  moreChannelLoading = false;

  // tslint:disable-next-line:no-any
  handleChange(info: any): void {
    const fileList = info.fileList;
    // 2. read from response and show file link
    if (info.file.response) {
      info.file.url = info.file.response.url;
    }
    // 3. filter successfully uploaded files according to response from server
    // tslint:disable-next-line:no-any
    this.fileList = fileList.filter((item: any) => {
      if (item.response) {
        return item.response.success;
      }
      return true;
    });
    this.attachmentsLoading = fileList.some(
      (item: any) => !item.response && item.status !== "error"
    );
  }
  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }
  @ViewChild("scrollElement", { static: false }) content: ElementRef;
  @ViewChild("notificationElement", { static: false })
  notificationElement: TemplateRef<{}>;
  contactListOpen = false;
  constructor(
    private requesterService: RequesterService,
    private notificationService: NzNotificationService,
    private chatDispatcherService: ChatDispatcherService,
    private msg: NzMessageService,
    private router: Router,
    private communicationService: CommunicationService
  ) {
    this.communicationService.floatingChatToggle.subscribe(() => {
      this.contactListOpen = !this.contactListOpen;
    })
  }

  async ngOnInit() {
    try {
      await this.authenticateUser();
      await this.getChannels();
    } catch (e) {
      console.log(e);
    }
    this.chatDispatcherService.openChat.subscribe((cid) => {
      this.openChatFromNotification(cid);
    });
    this.chatDispatcherService.channel.subscribe(async (data) => {
      console.log("Channel Data", data);
      try {
        await this.getChannels();
        const channelIndex = this.channelList.findIndex(
          (x) => x.id === data.id
        );
        if (channelIndex !== -1) {
          await this.openChat(this.channelList[channelIndex]);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  async openChat(channelData: ChannelData) {
    // this.chatWindowName = channelData.data.rfqNo;
    // this.rfqId = channelData.data.rfqId;
    // this.messages = [...channelData.state.messages];
    // this.singleExpanded = true;
    // this.openSingle = true;
    // this.updateScroll();
    // await this.openChannel(channelData);
  }
  closeChat() {
    this.messages = [];
    this.openSingle = false;
    this.clearMessageListener();
  }
  async authenticateUser() {
    // try {
    //   const response = await this.requesterService
    //     .request("get", "auth/authenticate-chat")
    //     .toPromise();
    //   const { token } = response.data;
    //   const apiKey = response.data.api_key;
    //   this.username = response.data.user.username;
    //   const name = response.data.user.name;
    //   this.chatClient = new StreamChat(apiKey);
    //   this.currentUser = await this.chatClient.setUser(
    //     {
    //       id: this.username,
    //       name,
    //     },
    //     token
    //   );
    //   console.log(this.currentUser);
    //   this.unreadCount = this.currentUser.me.total_unread_count;
    //   this.chatClient.on((event) => {
    //     if (
    //       event.type === "message.new" &&
    //       event.message.user.id !== this.currentUser.me.id
    //     ) {
    //       console.log(event);
    //       // Only Send Notification if the channel is not Open
    //       if (
    //         !this.channel ||
    //         this.channel.cid !== event.cid ||
    //         !this.openSingle
    //       ) {
    //         const userType = "Attender Admin";
    //         this.notificationService.template(this.notificationElement, {
    //           nzData: {
    //             userType,
    //             name: event.message.user.name,
    //             cid: event.cid,
    //             message: event.message.text,
    //           },
    //         });
    //       }
    //     }
    //     if (event.unread_count !== undefined) {
    //       this.channelList = []
    //       this.getChannels();
    //     }
    //     // console.log(event.total_unread_count)

    //     if (event.total_unread_count !== undefined) {
    //       this.unreadCount = event.total_unread_count;
    //       const unreadChannels = this.channelList.filter(
    //         (x) => x.state.read[this.currentUser.me.id].unread_messages
    //       );
    //       console.log("UNREAD", unreadChannels);
    //       this.chatDispatcherService.notificationCounter.next({
    //         count: this.unreadCount,
    //         channelList: unreadChannels,
    //       });
    //       this.chatDispatcherService.refreshCounters.next(null);
    //     }
    //   });
    // } catch (e) {
    //   throw e;
    // }
  }

  openChatFromNotification(cid: string) {
    const channel = this.channelList.find((x) => x.cid === cid);
    this.openChat(channel);
  }

  async getChannels() {
    // const filter = {
    //   type: "team",
    //   chatTeamType: 'vendor',
    //   members: { $in: [`${this.currentUser.me.id}`] },
    // };
    // const sort = { last_message_at: -1 };
    // this.moreChannelLoading = true;
    // try {
    //   this.currentChannelList = await this.chatClient.queryChannels(
    //     filter,
    //     sort,
    //     {
    //       watch: true,
    //       state: true,
    //       limit: this.limit,
    //       offset: this.channelList.length,
    //     }
    //   );
    //   this.moreChannelLoading = false;
    // } catch (e) {
    //   this.moreChannelLoading = false;
    //   console.log(e);
    // }

    // this.channelList = [
    //   ...this.channelList,
    //   ...this.currentChannelList.filter((x) => !!x.data.rfqNo),
    // ];
    // console.log("Channel List", this.channelList, this.currentUser.me.id,);

    // const unreadChannels = this.currentChannelList.filter(
    //   (x) => x.state.read[this.currentUser.me.id].unread_messages
    // );
    // console.log('UNR', unreadChannels)
    // this.chatDispatcherService.notificationCounter.next({
    //   count: this.unreadCount,
    //   channelList: unreadChannels,
    // });
  }
  async openChannel(channelData: ChannelData) {
    // this.clearMessageListener();
    // const channel = this.chatClient.channel(this.channelType, channelData.id);
    // await channel.watch();
    // this.channel = channel;
    // this.messages = channel.state.messages;
    // this.updateScroll();
    // await channel.markRead();
    // this.messageListener = this.channel.on(
    //   "message.new",
    //   this.messageEventHandler
    // );
  }
  messageEventHandler = (event) => {
    // this.messages = [...this.messages, event.message];
    // this.channel.markRead();
    // this.updateScroll();
  };

  clearMessageListener() {
    // if (this.channel) {
    //   this.channel.off("message.new", this.messageEventHandler);
    // }
  }

  async sendMessage() {
    // if (this.attachmentsLoading) {
    //   return;
    // }
    // this.messageSending = true;

    // const fileList = [...this.fileList].map((x) => x.response.data);
    // this.fileList = [];
    // if (this.newMessage.trim() === "" && !fileList.length) {
    //   this.messageSending = false;
    //   return;
    // }
    // console.log(fileList);
    // try {
    //   await this.channel.sendMessage({
    //     text: this.newMessage,
    //     fileAttachments: fileList,
    //   });
    //   this.newMessage = "";
    //   this.messageSending = false;
    // } catch (err) {
    //   console.log(err);
    // }
  }

  updateScroll() {
    setTimeout((_) => {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    }, 100);
  }

  onResizeEnd(event: ResizeEvent): void {
    console.log("Element was resized", event);
    this.customStyle.width = event.rectangle.width + "px";
    this.customStyle.height = event.rectangle.height - 100 + "px";
  }

  openRfqDetails(rfqId) {
    this.router.navigateByUrl("panel/bidding/" + this.rfqId + '/rfq-summary');
  }
}
